.card-container {
  display: grid;
  text-align: left;
  gap: var(--gap-sm);
  place-content: start;
  padding: var(--gap-xl);
  background-color: #fffcf7;
  grid-template-rows: 30px auto;
  border-bottom: 0.5px solid rgb(231, 231, 231);

  @media screen and (min-width: 641px) {
    grid-template-rows: 50px auto;
    border-left: 0.5px solid rgb(231, 231, 231);
  }

  .card-title {
    display: flex;
    align-items: center;
    font-size: var(--fs-md);
    font-weight: var(--fw-medium);
    text-transform: none !important;

    @media screen and (min-width: 641px) {
      text-align: left;
    }

    @media screen and (min-width: 961px) {
      text-align: left;
      font-size: var(--fs-lg);
    }

    @media screen and (min-width: 1281px) {
      font-size: var(--fs-xl);
    }
  }

  .card-desc {
    font-size: var(--fs-xs);
    font-weight: var(--fw-normal);

    > span {
      border-radius: var(--border-radius);
      background-color: var(--brand-light);
    }

    @media screen and (min-width: 641px) {
      font-size: var(--fs-sm);
    }

    @media screen and (min-width: 961px) {
      font-size: var(--fs-md);
    }
  }
}
