.explore-container {
  padding-top: 0 !important;
  background-color: var(--white);
}

.container {
  padding: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--white);

  > h2 {
    text-align: center;
    text-transform: none;
    font-size: var(--fs-3xl);
    font-weight: var(--fw-bold);

    > span {
      color: var(--brand-color);
    }

    @media screen and (min-width: 641px) {
      font-size: var(--fs-4xl);
    }

    @media screen and (min-width: 1025px) {
      font-size: var(--fs-5xl);
    }
  }

  > p {
    display: block;
    text-align: center;
    margin: var(--gap-md);
    font-size: var(--fs-sm);

    > span {
      font-weight: var(--fw-medium);
    }

    @media screen and (min-width: 641px) {
      font-size: var(--fs-md);
    }

    @media screen and (min-width: 961px) {
      font-size: var(--fs-lg);
    }
  }

  .main {
    display: grid;
    grid-template-columns: 1fr;
    gap: var(--gap-lg);
    width: 100%;

    @media screen and (min-width: 641px) {
      grid-template-columns: 1fr 1fr;
    }

    @media screen and (min-width: 1025px) {
      width: 100%;
    }

    @media screen and (min-width: 1281px) {
      width: 75%;
    }

    .left,
    .right {
      display: grid;
      grid-template-rows: auto auto auto;
      gap: var(--gap-lg);
    }

    .left {
      grid-column: 1;
      width: 100%;

      @media screen and (min-width: 641px) {
        width: 100%;
      }
    }

    .right {
      grid-column: 1;
      width: 100%;

      @media screen and (min-width: 641px) {
        grid-column: 2;
      }
    }

    .left > article,
    .right > article {
      height: 100%;
      padding: 10px;
      display: flex;
      flex-direction: column;
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23F3912EFF' stroke-width='2' stroke-dasharray='6%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");

      @media screen and (min-width: 641px) {
        padding: 15px;
      }

      > div {
        height: 100%;
        display: flex;
        overflow: hidden;
        gap: var(--gap-sm);
        flex-direction: column;
        background-color: var(--menu-back);
        border-radius: var(--border-radius);
        padding: var(--gap-md) var(--gap-md) 0 var(--gap-md);

        @media screen and (min-width: 641px) {
          gap: var(--gap-md);
          padding: var(--gap-xl) var(--gap-xl) 0 var(--gap-xl);
        }

        > h3 {
          // height: 100%;
          text-transform: none;
          font-size: var(--fs-xl);

          @media screen and (min-width: 641px) {
            font-size: var(--fs-2xl);
          }
        }

        > p {
          font-size: var(--fs-sm);

          @media screen and (min-width: 641px) {
            font-size: var(--fs-md);
          }

          > span {
            font-weight: var(--fw-bold);
            background-color: #f8e5c5;
          }
        }

        > a {
          color: var(--blue);
          text-decoration: none;
          font-size: var(--fs-sm);

          @media screen and (min-width: 641px) {
            font-size: var(--fs-md);
          }
        }
      }

      .template-1 {
        > img {
          left: 10%;
          height: auto;
          max-width: 70%;
        }
      }

      .template-2 {
        > img {
          left: 50%;
          height: auto;
          max-width: 50%;
        }

        > .link {
          color: var(--blue);
          text-decoration: none;
          font-size: var(--fs-sm);

          @media screen and (min-width: 641px) {
            font-size: var(--fs-md);
          }
        }
      }

      .template-3 {
        > img {
          left: 30%;
          height: auto;
          max-width: 80%;
        }

        .cipherpoints-bold {
          background: none;
          font-weight: var(--fw-bold);
        }
      }

      .template-4 {
        > img {
          left: 60%;
          max-width: 50%;
          height: inherit;
        }
      }

      .template-5 {
        > img {
          left: 15%;
          max-width: 90%;
          height: inherit;
        }

        .plutonn {
          color: #f45046;
          background: none;
          font-weight: var(--fw-medium);
        }
      }
      .template-6 {
        > img {
          left: -10%;
          max-width: 80%;
          height: inherit;
        }

        > .link {
          > p {
            cursor: pointer;
            color: var(--blue);
            text-decoration: none;
            font-size: var(--fs-sm);

            @media screen and (min-width: 641px) {
              font-size: var(--fs-md);
            }
          }
        }
      }

      .plutonnHeading {
        display: flex;
        gap: var(--gap-sm);
        align-items: center;

        > h3 {
          display: block;
          text-transform: none;
          font-size: var(--fs-xl);

          @media screen and (min-width: 641px) {
            font-size: var(--fs-2xl);
          }
        }

        > img {
          width: 35px;
          height: auto;
        }
      }
    }
  }
  .btn {
    cursor: pointer;
    padding: 10px 20px;
    color: var(--white);
    margin-top: var(--gap-xl);
    font-weight: var(--fw-bold);
    border-radius: var(--border-radius);
    background-color: var(--brand-color);
  }
}
