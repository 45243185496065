.container {
  width: 100%;
  height: 250px;
  padding: 5px 0;
  display: block;
  background: var(--white);
  border-radius: var(--border-radius);

  @media screen and (min-width: 641px) {
    height: 280px;
    padding: 15px 0;
  }

  @media screen and (min-width: 961px) {
    height: 250px;
    padding: 15px 0;
  }

  > img {
    height: 30px;
    max-width: 90px;
    padding-left: 10px;
    object-fit: contain;
    @media screen and (min-width: 641px) {
      max-width: 100px;
      height: 35px;
    }
  }

  .content {
    display: grid;
    height: 100%;
    padding: var(--gap-md);
    box-sizing: content-box;
    grid-template-rows: 0.75fr 40px;

    > p {
      font-size: var(--fs-sm);

      @media screen and (min-width: 641px) {
        font-size: var(--fs-md);
      }

      > button {
        color: var(--blue);
      }
    }

    @media screen and (min-width: 641px) {
      grid-template-rows: 0.8fr 60px;
    }
  }

  .alumni-info {
    display: flex;
    gap: var(--gap-md);
    align-items: center;

    > img {
      width: auto;
      height: 40px;
      aspect-ratio: 1/1;
      border-radius: 50%;
    }

    .alumni {
      display: block;

      > h3 {
        line-height: 18px;
        font-size: var(--fs-sm);
        font-weight: var(--fw-medium) !important;

        @media screen and (min-width: 641px) {
          line-height: 22px;
          font-size: var(--fs-md);
        }
      }

      > p {
        overflow: hidden;
        line-height: 14px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        font-size: var(--fs-xs);
        -webkit-box-orient: vertical;

        @media screen and (min-width: 641px) {
          line-height: 20px;
          font-size: var(--fs-sm);
        }
      }
    }
  }
}
