.mission-container {
  padding-top: 0px !important;
  background-color: var(--white);
}

.container {
  > main {
    > header {
      display: flex;
      gap: var(--gap-md);
      flex-direction: column;
      padding-bottom: var(--gap-md);

      @media screen and (min-width: 641px) {
        padding-bottom: var(--gap-lg);
      }

      @media screen and (min-width: 961px) {
        padding-bottom: var(--gap-xl);
      }

      > h2 {
        display: block;
        text-align: center;
        font-size: var(--fs-3xl);
        text-transform: none !important;

        > span {
          color: var(--brand-color);
        }

        @media screen and (min-width: 641px) {
          font-size: var(--fs-4xl);
        }

        @media screen and (min-width: 1025px) {
          font-size: var(--fs-5xl);
        }
      }

      > p {
        text-align: center;
        font-size: var(--fs-sm);

        span {
          font-weight: var(--fw-medium);
        }

        @media screen and (min-width: 641px) {
          font-size: var(--fs-md);
        }

        @media screen and (min-width: 961px) {
          font-size: var(--fs-lg);
        }
      }
    }

    .cards {
      padding: 1px;
      display: grid;
      margin: 0 var(--gap-section);
      grid-template-columns: repeat(1, 1fr);
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23F3912EFF' stroke-width='2' stroke-dasharray='6%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");

      @media screen and (min-width: 641px) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media screen and (min-width: 1025px) {
        margin: 0;
        grid-template-columns: repeat(4, 1fr);
      }
    }

    .inner-container {
      padding-top: var(--gap-md);

      @media screen and (min-width: 641px) {
        padding-top: var(--gap-lg);
      }

      @media screen and (min-width: 961px) {
        display: flex;
        width: 100%;
        padding-top: var(--gap-xl);
        justify-content: space-between;
      }

      .left {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;

        @media screen and (min-width: 961px) {
          width: 50%;
        }

        .phone-img {
          width: 95%;
        }
      }

      .right {
        width: 100%;
        display: flex;
        gap: var(--gap-md);
        flex-direction: column;

        @media screen and (min-width: 961px) {
          width: 50%;
        }

        .title {
          display: block;
          text-align: center;
          text-transform: none;
          font-size: var(--fs-2xl);
          font-weight: var(--fw-medium);

          @media screen and (min-width: 641px) {
            font-size: var(--fs-3xl);

            .break-line {
              display: none;
            }
          }

          @media screen and (min-width: 961px) {
            text-align: left;
          }

          @media screen and (min-width: 1025px) {
            font-size: var(--fs-4xl);
          }

          span {
            color: var(--brand-color);
          }
        }

        .phone-img {
          width: 95%;
        }

        .accordian {
          gap: var(--gap-lg);
          width: 100%;
          height: 500px;
          display: flex;
          flex-direction: column;
          padding: var(--gap-xl) 0;
          text-transform: none !important;

          @media screen and (min-width: 641px) {
            width: 100%;
            padding: var(--gap-xl);
          }

          @media screen and (min-width: 961px) {
            height: 520px;
            width: 100%;
            text-align: left;
            padding: var(--gap-lg) 0;
          }

          @media screen and (min-width: 1281px) {
            width: 75%;
          }

          .accordion-item {
            > p {
              font-size: var(--fs-xs);
              padding: 15px var(--gap-xl);
              border-left: 3px solid var(--brand-color);

              @media screen and (min-width: 641px) {
                font-size: var(--fs-md);
              }
            }
          }
        }
      }

      .mobile-show {
        display: flex;
        @media screen and (min-width: 961px) {
          display: none;
        }

        > img {
          width: 95%;
          height: auto;
        }
      }

      .mobile-hide {
        display: none;
        @media screen and (min-width: 961px) {
          display: flex;
        }

        > img {
          width: 95%;
          height: auto;
        }
      }
    }
  }
}
