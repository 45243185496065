.anim,
.anim > svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  // z-index: -1;
}

.anim path {
  stroke-width: 0.2;
}
