.creator-container {
  padding-top: 0px !important;
  background-color: #f2f4f7;
}

.container {
  display: flex;
  gap: var(--gap-md);
  flex-direction: column;
  padding: var(--gap-lg) 0;

  @media screen and (min-width: 641px) {
    padding: var(--gap-xl) 0;
  }
  > h2 {
    display: block;
    text-align: center;
    text-transform: none;
    font-size: var(--fs-3xl);
    font-weight: var(--fw-medium);

    > span {
      font-weight: var(--fw-bold);
    }

    @media screen and (min-width: 641px) {
      font-size: var(--fs-4xl);
    }

    @media screen and (min-width: 1025px) {
      text-align: left;
      font-size: var(--fs-5xl);
    }
  }

  > main {
    display: flex;
    max-width: 100%;
    gap: var(--gap-xl);
    flex-direction: column-reverse;

    @media screen and (min-width: 961px) {
      display: grid;
      gap: var(--gap-lg);
      grid-template-columns: 1fr 1fr;
    }

    .left {
      width: 100%;
      display: flex;
      gap: var(--gap-xl);
      align-items: center;
      flex-direction: column;
      justify-content: center;

      @media screen and (min-width: 961px) {
        gap: var(--gap-md);
        align-items: flex-start;
        justify-content: flex-start;
      }

      .points {
        display: flex;
        gap: var(--gap-xl);
        align-items: flex-start;
        flex-direction: column;

        @media screen and (min-width: 961px) {
          padding: var(--gap-xl) 0;
        }

        > h3 {
          text-transform: none;
          font-size: var(--fs-md);
          padding-left: var(--gap-xl);
          font-weight: var(--fw-medium);

          @media screen and (min-width: 641px) {
            font-size: var(--fs-lg);
          }

          @media screen and (min-width: 961px) {
            padding-left: 0;
            font-size: var(--fs-xl);
          }
        }
      }

      > Button {
        font-weight: var(--fw-bold);
      }
    }

    .right {
      width: 100%;
      text-align: center;

      @media screen and (min-width: 961px) {
        text-align: left;
      }

      .creators-img {
        position: relative;
        padding-top: var(--gap-xl);

        img {
          object-fit: cover;
          padding: var(--gap-sm);
          background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23F3912EFF' stroke-width='2' stroke-dasharray='6%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
        }

        > div:nth-child(1),
        div:nth-child(2),
        div:nth-child(4),
        div:nth-child(5) {
          z-index: 1;
        }

        .creator-1 > img,
        .creator-2 > img,
        .creator-3 > img,
        .creator-4 > img {
          width: 80px;
          height: 80px;
          position: absolute;

          @media screen and (min-width: 641px) {
            width: 130px;
            height: 130px;
          }
        }

        .creator-1 > img {
          left: -5px;
          top: -20px;

          @media screen and (min-width: 961px) {
            left: -40px;
          }
        }

        .creator-2 > img {
          right: -5px;
          top: -10px;

          @media screen and (min-width: 961px) {
            right: -30px;
            top: -40px;
          }
        }

        .bgimage > img {
          max-width: 90%;
          height: auto;
          z-index: 0;

          @media screen and (min-width: 641px) {
            width: 80%;
          }

          @media screen and (min-width: 641px) {
            width: 100%;
          }
        }

        .creator-3 > img {
          top: -70px;
          left: 50px;

          @media screen and (min-width: 641px) {
            top: -90px;
            left: 50px;
          }
        }

        .creator-4 > img {
          top: -90px;
          right: 10px;

          @media screen and (min-width: 641px) {
            top: -120px;
            right: 30px;
          }
        }
      }
    }
  }
}
