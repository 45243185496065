.container {
  text-align: center;

  .images {
    display: inline-block;

    .profile-img > img {
      width: 120px;
      height: 120px;
      display: block;
      object-fit: cover;
      border-radius: 50%;

      @media screen and (min-width: 641px) {
        width: 150px;
        height: 150px;
      }
      @media screen and (min-width: 961px) {
        width: 160px;
        height: 160px;
      }
      @media screen and (min-width: 1025px) {
        width: 170px;
        height: 170px;
      }
    }

    .company-img > img {
      right: 0;
      bottom: 0;
      width: 35px;
      height: 35px;
      position: absolute;
      border-radius: 50%;
      object-fit: contain;
      border: 2px solid var(--white);
      background-color: var(--white);
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);

      @media screen and (min-width: 641px) {
        width: 45px;
        height: 45px;
      }
      @media screen and (min-width: 961px) {
        width: 50px;
        height: 50px;
      }
      @media screen and (min-width: 1025px) {
        width: 55px;
        height: 55px;
      }
    }
  }

  .content {
    .name {
      font-weight: var(--fw-medium);
      font-size: var(--fs-md);

      @media screen and (min-width: 641px) {
        font-size: var(--fs-lg);
      }
    }

    .role {
      display: block;
      font-size: var(--fs-xs);
      color: rgb(65, 65, 65);
      font-weight: var(--fw-light);

      @media screen and (min-width: 641px) {
        font-size: var(--fs-md);
      }
    }
  }
}
