.accordion-title {
  cursor: pointer;
  text-align: left;
  font-size: var(--fs-md);
  border-left: 3px solid #cbd5e1;
  padding: var(--gap-md) var(--gap-lg);

  @media screen and (min-width: 641px) {
    font-size: var(--fs-lg);
  }

  @media screen and (min-width: 961px) {
    text-align: left;
    font-size: var(--fs-xl);
  }

  &:hover {
    border-left-color: var(--brand-color);
  }

  h4 {
    margin: 0;
    text-transform: none;
    font-weight: var(--fw-medium);
  }
}

.open {
  border-left-color: var(--brand-color);
  text-align: left;
}
