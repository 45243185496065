.container {
  height: 100%;
  display: flex;
  position: relative;
  color: var(--white);
  padding: 20px 10px;
  padding-bottom: 100px;
  flex-direction: column;
  background-color: var(--dark-background);

  @media screen and (min-width: 641px) {
    padding: 20px;
    padding-bottom: 100px;
  }

  @media screen and (min-width: 961px) {
    padding: 20px 40px;
    padding-bottom: 50px;
    height: calc(100vh - 20px);
  }

  @media screen and (min-width: 1281px) {
    padding-bottom: 0px;
    padding: var(--gap-section) 60px;
    height: calc(100vh - 60px);
  }

  @media screen and (min-width: 1400px) {
    padding: var(--gap-section) 80px;
  }

  .bg-animation {
    top: 0;
    left: 0;
    width: 50%;
    position: absolute;
    // border: 1px solid red;

    @media screen and (max-width: 961px) {
      display: none;
    }
  }

  .gradient {
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
    position: absolute;
    z-index: 1;
    background: linear-gradient(
      to right,
      rgba(212, 212, 212, 0) 0%,
      rgba(107, 107, 107, 0.6) 30%,
      rgba(51, 51, 51, 0.8) 100%
    );

    @media screen and (max-width: 961px) {
      display: none;
    }
  }

  > main {
    display: flex;
    width: 100%;
    height: 100%;
    gap: var(--gap-lg);
    flex-direction: column;
    padding: var(--gap-sm) 0px;

    @media screen and (min-width: 961px) {
      flex-direction: row;
    }

    .feature {
      width: 100%;
      padding: 10px;
      display: block;
      line-clamp: unset;
      line-height: 17px;
      width: fit-content;
      text-align: center;
      text-transform: none;
      color: var(--white);
      font-size: var(--fs-sm);
      -webkit-line-clamp: unset;
      font-weight: var(--fw-normal);
      // height: calc(var(--fs-sm) + 20px);
      background-color: var(--dark-background);
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23FFFEFEFF' stroke-width='1' stroke-dasharray='6%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");

      @media screen and (min-width: 641px) {
        height: calc(var(--fs-md) + 20px);
        font-size: var(--fs-md);
      }
      @media screen and (min-width: 1281px) {
        font-size: var(--fs-lg);
        height: calc(var(--fs-lg) + 20px);
      }

      &.highlight {
        border-color: var(--brand-color);
      }
    }

    .orangeborder {
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23F3912EFF'  stroke-width='1' stroke-dasharray='6%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    }

    @media screen and (min-width: 961px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    .left {
      z-index: 10;
      width: 100%;
      display: flex;
      margin: auto 0;
      padding-top: 30px;
      gap: var(--gap-xl);
      text-align: center;
      color: var(--white);
      align-items: center;
      flex-direction: column;

      @media screen and (min-width: 961px) {
        padding-top: 0px;
        text-align: left;
        gap: var(--gap-section);
        align-items: flex-start;
      }

      > h1 {
        text-transform: none;
        font-size: var(--fs-4xl);
        span {
          color: var(--brand-color);
        }

        @media screen and (min-width: 641px) {
          font-size: var(--fs-5xl);
        }
        @media screen and (min-width: 1281px) {
          font-size: var(--fs-6xl);
        }
      }

      .subtext {
        line-clamp: unset;
        font-size: var(--fs-sm);
        -webkit-line-clamp: unset;
        font-weight: var(--fw-normal);
        text-transform: none !important;

        @media screen and (min-width: 641px) {
          width: 80%;
          font-size: var(--fs-lg);
        }

        @media screen and (min-width: 961px) {
          font-size: var(--fs-xl);
        }
      }

      .info-area {
        display: flex;
        gap: var(--gap-md);
        flex-direction: column;

        @media screen and (min-width: 961px) {
          flex-direction: row;
        }

        .images {
          display: flex;

          & > div {
            &:nth-child(1) {
              z-index: 5;
            }
            &:nth-child(2) {
              z-index: 4;
              margin-left: -10px;
            }
            &:nth-child(3) {
              z-index: 3;
              margin-left: -10px;
            }
            &:nth-child(4) {
              z-index: 2;
              margin-left: -10px;
            }
            &:nth-child(5) {
              z-index: 1;
              margin-left: -10px;
            }
          }

          .image > img {
            width: 35px;
            height: auto;
            aspect-ratio: 1/1;
            border-radius: 50%;
            border: 2px solid var(--brand-color);
            background-color: var(--dark-background);

            @media screen and (min-width: 641px) {
              width: 40px;
            }
            @media screen and (min-width: 961px) {
              width: 45px;
            }
          }
        }

        .texts {
          text-align: center;
          color: var(--white);
          font-size: var(--fs-sm);
          font-weight: var(--fw-normal);

          @media screen and (min-width: 961px) {
            text-align: left;
          }
        }
      }
    }

    .right {
      z-index: 10;
      height: 100%;
      display: flex;
      flex-direction: column;
      padding: var(--gap-lg) 0px;

      @media screen and (min-width: 961px) {
        padding: 0px;
      }

      .feature1 {
        top: 10px;
        position: absolute;

        @media screen and (min-width: 641px) {
          top: 20%;
          left: 10%;
        }
        @media screen and (min-width: 961px) {
          top: 2%;
          left: unset;
        }
      }

      .feature2 {
        top: 0px;
        right: 0px;
        position: absolute;

        @media screen and (min-width: 961px) {
          top: 12%;
          right: 20px;
        }

        @media screen and (min-width: 1281px) {
          top: 5%;
        }
      }

      > span {
        right: -40%;
        min-width: 100px;
        display: inline-block;
        width: auto !important;
        height: auto !important;
        margin: var(--gap-xl) 0px;
        img {
          width: 100%;
          height: 100%;
          border-right: none;
          border: 5px solid gainsboro;
          border-radius: var(--border-radius);
        }

        @media screen and (min-width: 961px) {
          top: 50%;
          right: -80px;
          margin: auto 0;
          position: absolute;
          transform: translateY(-50%);
        }
      }

      .feature3 {
        left: 50%;
        bottom: 0px;
        position: absolute;

        @media screen and (min-width: 961px) {
          bottom: 10%;
          left: -50%;
          transform: translateX(40%);
        }
        @media screen and (min-width: 1281px) {
          bottom: 17%;
          left: -60%;
        }
      }

      .feature4 {
        left: 0px;
        bottom: 30%;
        display: block;
        max-width: 140px;
        position: absolute;

        @media screen and (min-width: 641px) {
          left: 10px;
          bottom: 40%;
          max-width: 200px;
        }

        @media screen and (min-width: 961px) {
          right: 0px;
          left: unset;
          bottom: 8%;
          max-width: 220px;
        }

        @media screen and (min-width: 1281px) {
          right: 40%;
          left: unset;
          bottom: 4%;
          max-width: unset;
        }
      }
    }
  }

  .mobile-show {
    display: flex !important;

    @media screen and (min-width: 961px) {
      display: none !important;
    }
  }

  .mobile-hide {
    display: none !important;

    @media screen and (min-width: 961px) {
      display: flex !important;
    }
  }

  .scroll-down {
    bottom: 0;
    left: 50%;
    padding: 30px 0;
    position: absolute;
    transform: translateX(-50%);
  }
}
