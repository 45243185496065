.container {
  margin: 0 !important;
  padding: var(--gap-xl) 0;
  max-width: 100% !important;
  background-color: #fffaf2;

  > h2 {
    color: var(--black);
    text-align: center;
    text-transform: none;
    font-size: var(--fs-3xl);
    font-weight: var(--fw-bold);

    @media screen and (min-width: 641px) {
      font-size: var(--fs-4xl);
    }

    @media screen and (min-width: 1025px) {
      font-size: var(--fs-5xl);
    }
  }

  .mentor-container {
    padding: 0;
    width: 100%;
    display: block;
    flex-basis: 100%;

    .mentors {
      margin-top: var(--gap-xl);
    }
  }
}
