.container {
  header {
    width: 100%;
    text-align: center;
    background-color: var(--white);
    padding-bottom: var(--gap-lg);

    > h2 {
      font-size: var(--fs-3xl);
      font-weight: var(--fw-bold);
      text-transform: none !important;

      @media screen and (min-width: 641px) {
        font-size: var(--fs-4xl);
      }

      @media screen and (min-width: 1281px) {
        font-size: var(--fs-5xl);
      }
    }

    > p {
      line-height: 1.5;
      font-size: var(--fs-sm);
      font-weight: var(--fw-normal);
      span {
        font-weight: var(--fw-medium);
      }

      @media screen and (min-width: 641px) {
        font-size: var(--fs-md);
      }

      @media screen and (min-width: 961px) {
        font-size: var(--fs-lg);
      }
    }
  }
}

.alumni-data {
  padding: var(--gap-lg) 0;
  background-color: var(--dark-background);

  .top {
    padding-bottom: var(--gap-lg);
  }
}

.image {
  img {
    max-width: 150px;
    object-fit: contain;
  }
}

.modalContent {
  display: flex;
  gap: var(--gap-md);
  flex-direction: column;

  .content {
    display: grid;
    height: 100%;
    gap: var(--gap-lg);
    grid-template-rows: 1fr 40px;

    @media screen and (min-width: 641px) {
      grid-template-rows: 1fr 60px;
    }

    > p {
      font-size: var(--fs-sm);

      @media screen and (min-width: 641px) {
        font-size: var(--fs-md);
      }
    }

    .alumni-info {
      display: flex;
      gap: var(--gap-md);
      align-items: center;

      img {
        width: auto;
        height: 40px;
        aspect-ratio: 1/1;
        border-radius: 50%;
      }

      .alumni {
        display: block;

        > h3 {
          font-size: var(--fs-md);
          font-weight: var(--fw-medium);
        }

        > p {
          display: block;
          font-size: var(--fs-sm);

          @media screen and (min-width: 641px) {
            font-size: var(--fs-sm);
          }
        }
      }
    }
  }
}
