.page-container {
  display: flex;
  flex-direction: column;
  gap: var(--gap-section);
  background-color: var(--white);

  .footer {
    background-color: var(--white);
    p {
      color: var(--black);
    }
    h1 {
      color: var(--black);
    }
    h5 {
      color: var(--black);
    }
  }

  .minifooter {
    color: var(--black);
    background-color: var(--menu-back);
  }
}

.reviewModal {
  width: 90%;
  padding: var(--gap-lg);
  background-color: var(--white) !important;

  @media screen and (min-width: 961px) {
    width: 50%;
  }

  @media screen and (min-width: 1281px) {
    width: 40%;
  }

  > header {
    padding: var(--gap-md) 0 !important;
    display: flex !important;
    flex-direction: row-reverse !important;
    align-items: center !important;
    justify-content: space-between !important;
    background-color: var(--white) !important;

    > button {
      > svg {
        color: rgb(180, 180, 180) !important;
      }
    }

    > h2 {
      padding: 0 !important;
      font-weight: var(--fw-bold) !important;
      color: black !important;
    }
    > div {
      display: none;
    }
  }

  .modalContent {
    display: flex;
    gap: var(--gap-md);
    flex-direction: column;

    > h1 {
      font-size: var(--fs-xl);
    }

    > hr {
      border: 0.1px solid rgb(180, 180, 180);
    }

    .details {
      display: flex;
      gap: var(--gap-md);
      align-items: center;

      > span {
        width: auto !important;
        height: auto !important;

        img {
          width: auto;
          height: 40px;
          aspect-ratio: 1/1;
          border-radius: 50%;
        }
      }

      > p {
        line-height: 17px;
        font-size: var(--fs-sm);
        font-weight: var(--fw-medium);
      }
    }
  }
}

.testimonialModal {
  width: 90%;
  padding: var(--gap-lg);
  background-color: var(--white) !important;

  @media screen and (min-width: 961px) {
    width: 50%;
  }

  @media screen and (min-width: 1281px) {
    width: 40%;
  }

  > header {
    display: flex !important;
    padding: var(--gap-md) 0 !important;
    flex-direction: row-reverse !important;
    align-items: center !important;
    justify-content: space-between !important;
    background-color: var(--white) !important;

    > button {
      > svg {
        color: rgb(180, 180, 180) !important;
      }
    }

    > h2 {
      padding: 0 !important;
      font-weight: var(--fw-bold) !important;
      color: black !important;
    }
    > div {
      display: none;
    }
  }

  .modalContent {
    display: flex;
    gap: var(--gap-lg);
    flex-direction: column;

    > hr {
      border: 0.1px solid rgb(180, 180, 180);
    }

    > span {
      width: 250px !important;
      height: auto !important ;
      background-color: var(--white);

      img {
        max-width: 150px !important;
        object-fit: contain;
      }
    }

    .content {
      height: 100%;
      display: grid;
      gap: var(--gap-lg);
      box-sizing: content-box;
      grid-template-rows: 0.9fr 40px;

      > p {
        font-size: var(--fs-dm);

        @media screen and (min-width: 961px) {
          font-size: var(--fs-md);
        }
      }

      @media screen and (min-width: 641px) {
        grid-template-rows: 0.9fr 60px;
      }

      .alumni-info {
        display: flex;
        gap: var(--gap-md);
        align-items: center;

        > span {
          width: auto !important;
          height: auto !important;

          img {
            width: auto;
            height: 60px;
            aspect-ratio: 1/1;
            border-radius: 50%;
          }

          @media screen and (min-width: 641px) {
            height: 40px;
          }
        }

        .alumni {
          display: block;

          > h3 {
            font-size: var(--fs-sm);
            font-weight: var(--fw-bold) !important;

            @media screen and (min-width: 641px) {
              font-size: var(--fs-lg);
            }
          }

          > p {
            display: block;
            font-size: var(--fs-sm);

            @media screen and (min-width: 641px) {
              font-size: var(--fs-sm);
            }
          }
        }
      }
    }
  }
}
