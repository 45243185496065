.studfeed-card {
  padding-top: 0 !important;
  background: var(--white);
}

.container {
  display: flex;
  flex-direction: column;
  gap: var(--gap-xl);
  overflow: hidden;

  > h2 {
    width: 100%;
    margin-top: 2px;
    text-align: center;
    text-transform: none;
    font-size: var(--fs-3xl);
    font-weight: var(--fw-bold);

    @media screen and (min-width: 641px) {
      font-size: var(--fs-4xl);
    }

    @media screen and (min-width: 1025px) {
      font-size: var(--fs-5xl);
    }
  }

  .feedcards {
    width: 100%;
    display: grid;
    padding-bottom: 40px;

    :global {
      .swiper-wrapper {
        height: auto;
      }

      .swiper-pagination {
        bottom: 10px;
        z-index: 10;
        width: 120px !important;
      }

      .swiper-pagination-bullet {
        background-color: #94a3b8;
        opacity: 0.7;
        width: 10px;
        height: 10px;
        border-radius: 100%;
        margin: 0 4px;
      }

      .swiper-pagination-bullet-active {
        background-color: var(--brand-color);
        opacity: 1;
        width: 40px;
        border-radius: 25px;
        height: 10px;
      }
    }

    @media screen and (max-width: 441px) {
      padding-left: 0 10px;
    }
  }
}

.modalContent {
  display: grid;
  height: 100%;

  .content {
    display: flex;
    gap: var(--gap-md);
    align-items: center;

    > span {
      width: auto !important;
      height: auto !important ;

      img {
        width: 40px;
        height: auto;
        aspect-ratio: 1/1;
        border-radius: 50%;
        object-fit: contain;
      }
    }

    > p {
      font-size: var(--fs-md);
    }
  }
}

.header {
  display: flex;
  gap: var(--gap-md);
  align-items: center;

  > img {
    height: 100%;
    border-radius: 50%;
  }

  .alumni {
    display: flex;
    align-items: center;
    h3 {
      font-size: var(--fs-md);
      font-weight: var(--fw-medium);
    }
  }
}
