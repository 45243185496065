.card {
  gap: var(--gap-lg);
  height: 180px;
  display: grid;
  padding: var(--gap-lg);
  box-sizing: content-box;
  border: 1px solid #d9d9d9;
  grid-template-rows: 0.9fr 10px;
  border-radius: var(--border-radius);

  @media screen and (min-width: 641px) {
    height: 200px;
    padding: var(--gap-lg);
  }

  @media screen and (min-width: 961px) {
    height: 180px;
    padding: var(--gap-xl);
  }

  > p {
    font-size: var(--fs-sm);

    @media screen and (min-width: 641px) {
      font-size: var(--fs-md);
    }

    > button {
      color: var(--blue);
    }
  }

  .profileModal {
    .modalContent {
      border: 1px solid red !important;
    }
  }

  .details {
    display: flex;
    gap: var(--gap-md);
    align-items: center;

    > img {
      width: auto;
      height: 40px;
      aspect-ratio: 1/1;
      border-radius: 50%;
    }

    > p {
      line-height: 17px;
      font-size: var(--fs-sm);
      font-weight: var(--fw-medium);

      @media screen and (min-width: 641px) {
        font-size: var(--fs-md);
      }

      > span {
        font-size: var(--fs-xs);
        font-weight: var(--fw-normal);
      }
    }
  }
}
