.joinus-container {
  padding-top: 0px !important;
  background-color: var(--dark-background);
}

.container {
  display: flex;
  color: var(--white);
  justify-content: center;
  flex-direction: column;
  padding: var(--gap-xl) 0;

  @media screen and (min-width: 961px) {
    flex-direction: row;
  }

  .left {
    width: 100%;
    display: flex;
    gap: var(--gap-lg);
    align-items: center;
    flex-direction: column;
    justify-content: center;

    @media screen and (min-width: 641px) {
      width: 100%;
    }

    @media screen and (min-width: 961px) {
      width: 50%;
      align-items: start;
      justify-content: start;
    }

    @media screen and (min-width: 961px) {
      padding: var(--gap-lg) 0;
    }

    @media screen and (min-width: 1281px) {
      padding: var(--gap-xl) 0;
    }

    > h2 {
      display: block;
      text-align: center;
      text-transform: none;
      font-size: var(--fs-3xl);
      font-weight: var(--fw-bold);

      @media screen and (min-width: 641px) {
        font-size: var(--fs-4xl);
      }

      @media screen and (min-width: 961px) {
        text-align: left;
      }
    }

    > p {
      display: block;
      line-height: 20px;
      text-align: center;
      text-transform: none;
      font-size: var(--fs-sm);
      font-weight: var(--fw-normal);

      @media screen and (min-width: 641px) {
        font-size: var(--fs-sm);
        padding: 0 var(--gap-xl);
      }

      @media screen and (min-width: 961px) {
        padding: 0;
        text-align: left;
      }

      @media screen and (min-width: 1025px) {
        font-size: var(--fs-md);
      }

      span {
        font-weight: var(--fw-bold);
      }
    }

    .appsContainer {
      display: flex;
      gap: var(--gap-md);
      align-items: center;

      > a {
        display: flex;
        cursor: pointer;
        gap: var(--gap-sm);
        align-items: center;
        padding: var(--gap-sm) var(--gap-md);
        background-color: black;
        color: var(--second-white);
        border: 1px solid var(--white);
        justify-content: space-between;
        border-radius: var(--border-radius);

        @media screen and (min-width: 961px) {
          padding: var(--gap-sm) var(--gap-md);
        }

        @media screen and (min-width: 1025px) {
          gap: var(--gap-sm);
        }

        .downloadIcon {
          color: var(--white);
          font-size: var(--fs-4xl);
        }

        > p {
          line-height: 20px;
          color: var(--white);
          font-size: var(--fs-sm);

          @media screen and (min-width: 641px) {
            font-size: var(--fs-md);
          }

          span {
            color: var(--white);
            font-size: var(--fs-xs);
          }
        }
      }
    }
  }

  .right {
    width: 100%;
    text-align: center;

    > img {
      width: 100%;
      height: auto;
      margin: auto;

      @media screen and (min-width: 641px) {
        width: 70%;
      }

      @media screen and (min-width: 961px) {
        width: 90%;
      }

      @media screen and (min-width: 1025px) {
        width: 80%;
      }

      @media screen and (min-width: 1281px) {
        width: 80%;
      }
    }
    @media screen and (min-width: 961px) {
      width: 50%;
    }
  }

  .mobile-show {
    display: flex !important;

    @media screen and (min-width: 961px) {
      display: none !important;
    }
  }

  .mobile-hide {
    display: none !important;

    @media screen and (min-width: 961px) {
      display: flex !important;
    }
  }
}
